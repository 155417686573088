<template>
  <div id="add-order-detail">
    <el-dialog title="【订单费用】" :close-on-click-modal="false" width="90%" :visible.sync="visible">
      <div class="searchBox">
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
          <el-form-item label="提货时间">
            <el-date-picker
              v-model="takeGoodsTime"
              type="daterange"
              align="right"
              unlink-panels
              :clearable="false"
              range-separator="-"
              size="small"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button @click="getDataList()" type="primary">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="tableBox tableBoxPop">
        <el-table :data="dataList"
                  :header-cell-class-name="cellClass"
                  ref="multipleTable"
                  :row-class-name="tableRowClassName"
                  @row-click="rowClick"
                  :row-style="selectedHighlight"
                  border size="mini"
                  :height="tableHeight"
                  :summary-method="getSummaries"
                  show-summary
                  :span-method="arraySpanMethod"
                  style="width: 100%;">
          <el-table-column label="序号" type="index">
          </el-table-column>
          <el-table-column label="提货时间" width="105" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{scope.row.orderInfo.takeGoodsDate + ' ' + scope.row.orderInfo.takeGoodsTime}}
            </template>
          </el-table-column>
          <el-table-column label="客户名称" prop="orderInfo.customerName" width="70" :show-overflow-tooltip="true">
          </el-table-column>
<!--          <el-table-column label="客户业务员" prop="orderInfo.customerBusinessName" width="80" :show-overflow-tooltip="true">-->
<!--          </el-table-column>-->
          <el-table-column label="客户联系人"  prop="orderInfo.customerContactName" width="70" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="客户单号"  prop="orderInfo.customerSelfNo" width="100" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="进仓单号" prop="orderInfo.inWarehouseNo" width="80" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="订单类型" :formatter="orderType" prop="orderInfo.orderType" width="60" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="报关方式" :formatter="entranceType" prop="orderInfo.entranceType" width="80" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="订单车型" :formatter="dictConvert" width="80" :show-overflow-tooltip="true" prop="orderInfo.vehicleType">
          </el-table-column>
          <el-table-column label="司机车牌"  prop="vehiclePlan.vehiclePlate" width="70" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="提货地址" prop="fullAddress" width="180" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="收货地址" prop="giveAddressInfo.fullAddress" width="180" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="运费" prop="costInfo.freightFee" :formatter="getNumValue" width="80"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="报关/入仓费" prop="costInfo.entranceFee" :formatter="getNumValue" width="80"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="高速费" prop="costInfo.highFee" :formatter="getNumValue" width="80"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="过磅费" prop="costInfo.weighFee" :formatter="getNumValue" width="80"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="装卸费" prop="costInfo.loadFee" :formatter="getNumValue" width="80"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="押夜/超时费" prop="costInfo.overtimeFee" :formatter="getNumValue" width="80"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="提货费" prop="costInfo.takeFee" :formatter="getNumValue" width="80"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="加点费" prop="costInfo.addFee" :formatter="getNumValue" width="80"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="停车费" prop="costInfo.parkingFee" :formatter="getNumValue" width="80"
                           :show-overflow-tooltip="true">
          </el-table-column>
          <!-- 应付所属费用 start -->
          <el-table-column label="打印费" prop="costInfo.printFee" :formatter="getNumValue" width="80"
                           v-if="this.dataForm.type == 2" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="维修费" prop="costInfo.maintainFee" :formatter="getNumValue" width="80"
                           v-if="this.dataForm.type == 2" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="现金加油费" prop="costInfo.refuelFee" :formatter="getNumValue" width="80"
                           v-if="this.dataForm.type == 2" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="现金过路费" prop="costInfo.passingFee" :formatter="getNumValue" width="80"
                           v-if="this.dataForm.type == 2" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="油卡费" prop="costInfo.refuelCardFee" :formatter="getNumValue" width="80"
                           v-if="this.dataForm.type == 2" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="粤通卡费" prop="costInfo.passingCardFee" :formatter="getNumValue" width="80"
                           v-if="this.dataForm.type == 2" :show-overflow-tooltip="true">
          </el-table-column>
          <!-- 应付所属费用 end -->
          <el-table-column :show-overflow-tooltip="true" width="80" :formatter="getNumValue" label="其他费用"
                           prop="costInfo.otherFee">
          </el-table-column>
          <el-table-column label="费用小计" prop="costInfo.auditTotalMoney" :formatter="getNumValue" width="60"
                           v-if="this.dataForm.type == 2" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="费用小计" prop="costInfo.totalMoney" :formatter="getNumValue" width="80"
                           v-if="this.dataForm.type == 1" :show-overflow-tooltip="true">
          </el-table-column>
        </el-table>
        <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
          :page-sizes="[20, 50, 100]" :page-size="dataForm.limit" :total="totalPage" layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button type="primary" @click="submit">确定</el-button> -->
        <el-button @click="visible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        page: 1,
        limit: 20,
        beginDate: null,
        endDate: null,
        businessId: null,
        writeStatus: null,
        type: null
      },
      tableHeight: '64vh',
      takeGoodsTime: null,
      pageIndex: 1, // 当前页
      totalPage: 0, // 总数
      companyList: [],
      dataList: [],
      getIndex: 1,
      pickerOptions: {},
      selectListArr: [],
      todo: 'view',
      type: ''
    }
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  methods: {
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex ) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    cellClass (row) {
      if (row.columnIndex === 0) {
        return 'DisabledSelection'
      }
    },
    getNumValue (row, cell, value) {
      return this.getValue(value)
    },
    getValue (value) {
      if (value == 0) {
        return ''
      } else {
        return value
      }
    },
    /**
     * 点击表格变色end
     */
    init (row, todo) {
      this.todo = todo
      this.visible = true
      this.type = todo
      this.dataForm.beginDate = row.beginDate
      this.dataForm.endDate = row.endDate
      this.dataForm.businessId = row.businessId
      this.dataForm.writeStatus = row.writeStatus
      this.dataForm.type = row.type
      this.pickerOptions = {
        disabledDate: time => {
          return !(time >= row.beginDate.getTime() && time <= row.endDate.getTime())
        }
      }
      this.takeGoodsTime = [this.dateFormatter(row.beginDate, 'yyyy-MM-dd'), this.dateFormatter(row.endDate, 'yyyy-MM-dd')]
      this.getDataList()
      // 解决elementUI table合计行初始页面不显示问题  this.height由父组件传递过来 this.theight未表格绑定的高度
      window.setTimeout(() => {
        this.tableHeight = '65vh'
      }, 1000)
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        let preId = ''
        const values = data.map(item => {
          if (item.orderInfo.id === preId) {
            return ''
          }
          preId = item.orderInfo.id
          if (column.property && column.property.indexOf('costInfo.') > -1) {
            let val = column.property.split('.')
            return this.getValue(Number(item[val[0]][val[1]]))
          }
        })
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return this.numFilter(this.getValue(Number(prev) + curr))
            } else {
              return this.numFilter(this.getValue(prev))
            }
          }, 0)
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    getAllSummaries (costInfo) {
      for (let c in costInfo) {
        costInfo[c] = this.getValue(costInfo[c])
      }
      // 合计参数，按顺序返回
      let param = []
      if (this.dataForm.type == 2) {
        param = ['合计', '', '', '', '', '', '', '', '', '', '', '',
          costInfo.freightFee,
          costInfo.entranceFee,
          costInfo.highFee,
          costInfo.weighFee,
          costInfo.loadFee,
          costInfo.overtimeFee,
          costInfo.takeFee,
          costInfo.addFee,
          costInfo.parkingFee,
          costInfo.printFee,
          costInfo.maintainFee,
          costInfo.refuelFee,
          costInfo.passingFee,
          costInfo.refuelCardFee,
          costInfo.passingCardFee,
          costInfo.otherFee,
          costInfo.auditTotalMoney]
      } else {
        param = ['合计', '', '', '', '', '', '', '', '', '', '', '',
          costInfo.freightFee,
          costInfo.entranceFee,
          costInfo.highFee,
          costInfo.weighFee,
          costInfo.loadFee,
          costInfo.overtimeFee,
          costInfo.takeFee,
          costInfo.addFee,
          costInfo.parkingFee,
          // costInfo.printFee,
          // costInfo.maintainFee,
          // costInfo.refuelFee,
          // costInfo.passingFee,
          // costInfo.refuelCardFee,
          // costInfo.passingCardFee,
          costInfo.otherFee,
          // costInfo.auditTotalMoney,
          costInfo.totalMoney]
      }
      // 创建列表行
      let tr = document.createElement('tr')
      // var itemTr = document.createElement('tr')
      // itemTr.innerHTML = document.querySelector('.el-table__footer-wrapper table tbody tr').innerHTML
      // 删除上一条合计
      if (document.querySelector('.tableBoxPop .el-table__footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.tableBoxPop .el-table__footer-wrapper table tbody').lastChild.remove()
      }
      // td赋值
      param.forEach((item, index) => {
        let td = document.createElement('td')
        td.innerHTML = item
        tr.appendChild(td)
      })
      // 添加合计
      // document.querySelector('.el-table__fixed-footer-wrapper table tbody').appendChild(tr)
      document.querySelector('.tableBoxPop .el-table__footer-wrapper table tbody').appendChild(tr)
    },
    getDataList () {
      this.dataListLoading = true
      let dataForm = JSON.parse(JSON.stringify(this.dataForm))
      let takeGoodsTime = this.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      dataForm.beginDate = timeParam.takeGoodsBeginDate
      dataForm.endDate = timeParam.takeGoodsEndDate
      this.$http({
        url: this.$http.adornUrl(`/costSummary/findYearTotalNumberList`),
        method: 'get',
        params: this.$http.adornParams(dataForm)
      }).then(({ data }) => {
        if (data) {
          this.getSpanArr(data.list)
          this.dataList = data.list
          this.totalPage = data.total
          this.getAllSummaries(data.summaryTotal || {})
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.dataForm.limit = val
      this.dataForm.page = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.dataForm.page = val
      this.getDataList()
    }
  }
}
</script>
<style lang="less">
#add-order-detail {
  .el-dialog {
    margin-top: 0 !important;
    top: 50%;
    transform: translate(0, -50%);
  }
  .el-dialog__header {
    padding: 10px 0px;
  }
  .el-dialog__body {
    padding: 0px 10px;
    .el-table .DisabledSelection .cell .el-checkbox {
      display: none;
      position: relative
    }
    // .el-table .DisabledSelection .cell:before {
    //   content: '选择';
    //   position: absolute;
    // }
  }
  .el-pagination {
    margin-top: 5px;
    span {
      font-size: 12px;
    }
    .el-input__inner {
      height: 22px !important;
    }
  }
  .el-dialog__footer {
    padding: 10px 10px;
    button.el-button {
      height: 26px;
      line-height: 0;
    }
  }
}
</style>
